import React, { useState } from 'react'

import { Typography } from '@material-ui/core'
import merge from 'lodash.merge'
import { useHistory, useParams } from 'react-router-dom'

import SelectLanguageScreen from './SelectLanguageScreen'
import SurveyNotLive from './SurveyNotLive'

import ErrorState from 'components/ErrorState'
import useGlobalStyles from 'components/lib/GlobalStyles'
import Loader from 'components/lib/Loader'
import NavBar from 'components/lib/NavBar'
import LoadingBlock from 'components/Survey/Blocks/LoadingBlock'
import TransitionBlock from 'components/Survey/Blocks/TransitionBlock'
import PersonalInfoQuestion from 'components/Survey/PersonalInfoQuestion'
import WelcomeScreen from 'components/WelcomeScreen'
import {
  usePublicOpenLinkSurveyQuery,
  useSavePersonalInfoResponsesMutation,
  BenchmarkCodeType,
  PublicOpenLinkSurveyQuery,
  SurveyStatusEnum,
  ParticipantSourceTypeInput,
  ParticipantSourceEnum,
} from 'generated/graphql'
import { useTranslations, TranslationKey } from 'locales'
import { getCompletionPercentageWithinRange, renderTemplate } from 'utils'
import { URLS } from 'utils/constants'
import { ResidentRespondentTypeEnum } from 'utils/generatedFrontendConstants'

export type PublicQuestions = NonNullable<
  NonNullable<PublicOpenLinkSurveyQuery['surveyById']>['participant']['questions']
>
export type PublicQuestion = NonNullable<PublicQuestions>[0]

enum ScreenStep {
  SELECT_LANGUAGE = 'language',
  WELCOME_SCREEN = 'welcome',
  PERSONAL_INFO_CONFIRMATION = 'personalInfoConfirmation',
  PERSONAL_INFO_QUESTIONS = 'personalInfoQuestions',
}

const questionHasOnlyOneChoice = (question: PublicQuestion) => {
  return question.choices?.length === 1
}

const getInitialResponses = (questions: PublicQuestions) => {
  const responses: { [code: string]: string } = {}
  questions.forEach(question => {
    if (question.benchmarkCode && questionHasOnlyOneChoice(question) && question.choices) {
      responses[question.benchmarkCode] = question.choices[0].code
    }
  })

  return responses
}

type Props = {
  surveyHashId: string
  isPreview?: boolean
  publicOpenLinkSurvey: NonNullable<PublicOpenLinkSurveyQuery['surveyById']>
}

const OpenLinkSurvey: React.FC<Props> = ({ isPreview, surveyHashId, publicOpenLinkSurvey }) => {
  const classes = useGlobalStyles()
  const { t } = useTranslations()
  const history = useHistory()

  const { participant, languages, type, welcomeMessage } = publicOpenLinkSurvey

  const initialQuestions = participant.questions as PublicQuestions

  const initialScreen =
    languages.length > 1 ? ScreenStep.SELECT_LANGUAGE : ScreenStep.WELCOME_SCREEN

  const [screenToShow, setScreenToShow] = useState(initialScreen)
  const [questionIndex, setQuestionIndex] = useState(0)
  const [responses, setResponses] = useState(getInitialResponses(initialQuestions))

  const [savePersonalInfo, { loading }] = useSavePersonalInfoResponsesMutation()

  const locationQuestion = initialQuestions.find(
    question => question.benchmarkCode === BenchmarkCodeType.AI_LOCATION,
  )

  const isPrimaryContactInResponses =
    responses[BenchmarkCodeType.RESIDENT_RESPONDENT_TYPE] ===
    ResidentRespondentTypeEnum.PRIMARY_CONTACT

  const questions = initialQuestions
    // If a resident is taking the survey, we don't want to ask them the client name question
    .filter(
      q =>
        q.benchmarkCode !== BenchmarkCodeType.CLIENT_NAME ||
        (q.benchmarkCode === BenchmarkCodeType.CLIENT_NAME && isPrimaryContactInResponses),
    )
    // We will hide the questions which have only one choice (for example the location question may only have one location as the possible answers,
    // case in which we will auto-respond with that location on their behalf)
    .filter(q => !questionHasOnlyOneChoice(q))
    // Translated the questions before anything
    .map(question => {
      return {
        ...question,
        text: t(question.text as TranslationKey),
      }
    })

  const personalInfoQuestionsArePrecoded = questions.length === 0

  let templates = JSON.parse(participant.templates)
  templates = Object.keys(templates).reduce(
    (acc, key) => ({ ...acc, [key]: t(templates[key] as TranslationKey) }),
    {},
  )

  let question = questions[questionIndex]
  if (question) {
    const locationResponse =
      locationQuestion?.benchmarkCode && responses[locationQuestion.benchmarkCode]
    const locationDisplayText = locationQuestion?.choices?.find(
      choice => choice.code === locationResponse,
    )?.text
    // When the location question has been answered, we need to save the location name as a template to be used in follow-up questions
    question = {
      ...question,
      text: renderTemplate(
        question.text,
        merge(templates, {
          location_name: locationDisplayText,
        }),
      ),
    }
  }

  const goBack = () => {
    switch (screenToShow) {
      case ScreenStep.WELCOME_SCREEN: {
        setScreenToShow(ScreenStep.SELECT_LANGUAGE)
        break
      }
      case ScreenStep.PERSONAL_INFO_CONFIRMATION: {
        setScreenToShow(ScreenStep.PERSONAL_INFO_QUESTIONS)
        break
      }
      case ScreenStep.PERSONAL_INFO_QUESTIONS: {
        if (questionIndex === 0) {
          setScreenToShow(ScreenStep.WELCOME_SCREEN)
        } else {
          setQuestionIndex(questionIndex - 1)
        }
        break
      }
      default:
        break
    }
  }

  const goNext = () => {
    if (questionIndex === questions.length - 1) {
      setScreenToShow(ScreenStep.PERSONAL_INFO_CONFIRMATION)
    } else {
      setQuestionIndex(questionIndex + 1)
    }
  }

  const names = {
    firstName: responses.firstName,
    lastName: responses.lastName,
    clientFirstName: responses.clientFirstName,
    clientLastName: responses.clientLastName,
  }

  const nonNameBenchmarkCodes = Object.keys(responses).filter(
    benchmarkCode => !Object.keys(names).includes(benchmarkCode),
  )

  const onSubmit = async () => {
    if (isPreview) {
      // We need to now redirect to PREVIEW instead of OPEN_LINK_SURVEY_PREVIEW because we are going the regular survey path now that the personal info questions have been asnwered
      history.push(`/${URLS.PREVIEW}/${surveyHashId}/${ParticipantSourceEnum.PORTAL}`)
      return
    }

    const result = await savePersonalInfo({
      variables: {
        surveyHashId,
        firstName: names.firstName,
        lastName: names.lastName,
        clientFirstName: isPrimaryContactInResponses ? names.clientFirstName : undefined,
        clientLastName: isPrimaryContactInResponses ? names.clientLastName : undefined,
        responses: nonNameBenchmarkCodes.map(code => {
          return {
            code,
            response: responses[code],
          }
        }),
      },
    })
    const participantHashId = result?.data?.savePersonalInfoResponses?.participantHashId
    if (participantHashId) {
      history.push(`/${URLS.SURVEY}/${participantHashId}/${ParticipantSourceTypeInput.C}`)
    }
  }

  const selectedAnswers: string[] = []

  if (screenToShow === ScreenStep.PERSONAL_INFO_CONFIRMATION) {
    // Populate selectedAnswers with all the questions answers to be displayed on the confirmation screen
    nonNameBenchmarkCodes.forEach(nonNameBenchmarkCode => {
      const choiceText = initialQuestions
        .find(q => q.benchmarkCode === nonNameBenchmarkCode)
        ?.choices?.find(choice => choice.code === responses[nonNameBenchmarkCode])?.text
      if (choiceText) {
        selectedAnswers.push(choiceText)
      }
    })
    if (names.firstName && names.lastName) {
      selectedAnswers.push(`${names.firstName} ${names.lastName}`)
      if (isPrimaryContactInResponses) {
        selectedAnswers.push(`${names.clientFirstName} ${names.clientLastName}`)
      }
    }
  }

  if (loading) {
    return <LoadingBlock />
  }

  if (screenToShow === ScreenStep.SELECT_LANGUAGE) {
    return (
      <SelectLanguageScreen
        goNext={() => setScreenToShow(ScreenStep.WELCOME_SCREEN)}
        languages={languages}
      />
    )
  }

  if (screenToShow === ScreenStep.WELCOME_SCREEN) {
    return (
      <WelcomeScreen
        surveyType={type}
        welcomeMessage={welcomeMessage}
        goBack={languages.length > 1 ? goBack : undefined}
        goNext={() => {
          if (personalInfoQuestionsArePrecoded) {
            setScreenToShow(ScreenStep.PERSONAL_INFO_CONFIRMATION)
          } else {
            setScreenToShow(ScreenStep.PERSONAL_INFO_QUESTIONS)
          }
        }}
        languages={languages}
        templates={templates}
      />
    )
  }

  return (
    <div>
      {!personalInfoQuestionsArePrecoded && <NavBar goBack={goBack} languages={languages} />}
      <div className={classes.screenContainer}>
        {screenToShow === ScreenStep.PERSONAL_INFO_CONFIRMATION ? (
          <TransitionBlock
            label={
              <div>
                <div>{t('Is your personal information correct?')}</div>
                {selectedAnswers.map(answer => (
                  <Typography variant="body1" key={answer}>
                    {t(answer as TranslationKey)}
                  </Typography>
                ))}
              </div>
            }
            nextLabel="Yes"
            goNext={onSubmit}
            skipLabel="No, edit information."
            onSkip={() => {
              if (personalInfoQuestionsArePrecoded) {
                setScreenToShow(ScreenStep.WELCOME_SCREEN)
              } else {
                setQuestionIndex(0)
                setScreenToShow(ScreenStep.PERSONAL_INFO_QUESTIONS)
              }
            }}
            footerNote="*Please note: Once saved, this action cannot be undone"
          />
        ) : (
          <PersonalInfoQuestion
            question={question}
            goNext={goNext}
            onResponseChange={(questionCode: string, response: string) => {
              setResponses({
                ...responses,
                [questionCode]: response,
              })
            }}
            isLastQuestion={questionIndex === questions.length}
            completionPercentage={getCompletionPercentageWithinRange(
              questionIndex,
              questions.length,
              0,
              15,
            )}
            responses={responses}
          />
        )}
      </div>
    </div>
  )
}

const OpenLinkSurveyContainer: React.FC<{ isPreview?: boolean }> = ({ isPreview }) => {
  const { surveyHashId } = useParams<{ surveyHashId: string }>()
  const { t, installCustomTranslations } = useTranslations({ useSuspense: false })

  const result = usePublicOpenLinkSurveyQuery({
    variables: { surveyHashId: surveyHashId || '', personalInfoQuestionsOnly: true },
  })
  if (result.loading) {
    return <Loader />
  }
  const publicOpenLinkSurvey = result?.data?.surveyById

  if (!publicOpenLinkSurvey) {
    return <ErrorState message={t('Error: This survey was not found.')} />
  }

  if (!isPreview && publicOpenLinkSurvey.status !== SurveyStatusEnum.LIVE) {
    return <SurveyNotLive startDate={publicOpenLinkSurvey.startDate} />
  }

  installCustomTranslations(publicOpenLinkSurvey.translations)

  return (
    <OpenLinkSurvey
      surveyHashId={surveyHashId || ''}
      publicOpenLinkSurvey={publicOpenLinkSurvey}
      isPreview={isPreview}
    />
  )
}

export default OpenLinkSurveyContainer
